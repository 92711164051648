import axios from 'axios';

const API_BASE_URL = 'https://api.app.agencymaison.com/api/v1';
//const API_BASE_URL = 'http://127.0.0.1:3000/api/v1';

function createAxiosInstance(needsAuth = true) {
    const headers = {};
    if (needsAuth) headers['Authorization'] = localStorage.getItem('token');

    return axios.create({
        baseURL: API_BASE_URL,
        headers
    });
}

class Api {
    static ACCESS_TOKEN = localStorage.getItem('token'); // This will be set dynamically by getAccessToken method.

    static listUsers = async () => {
        const api = createAxiosInstance(true);
        const response = await api.get('/users');
        return response.data;
    };

    static createUser = async (data) => {
        const api = createAxiosInstance(true);
        const response = await api.post('/users', {...data});
        return response.data;
    };

    static async post(endpoint, data) {
        const api = createAxiosInstance(true);
        const response = await api.post(endpoint, {...data});
        return response.data;
    }



    static async get(endpoint, params = {}) {
        const api = createAxiosInstance(true);
        const response = await api.get(endpoint, {params});
        return response.data;
    }

    static async put(endpoint, data) {
        const api = createAxiosInstance(true);
        const response = await api.put(endpoint, {...data});
        return response.data;
    }

    static async delete(endpoint) {
        const api = createAxiosInstance(true);
        const response = await api.delete(endpoint);
        return response.data;
    }
}

export default Api;
