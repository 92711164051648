import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import ListUsers from "./pages/users/list/ListUsers";
import CreateUser from "./pages/users/create/CreateUser";
import Home from "./pages/home/Home";
import ViewUser from "./pages/users/view/ViewUser";
import AuthCheck from "./pages/auth/AuthCheck";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import {AppContext} from "./context/AppContext";
import {useEffect, useState} from "react";
import Api from "./helpers/Api";
import {useChatClient} from "./hooks/useChatClient";
import ActivateAccount from "./pages/auth/ActivateAccount";
import ContactUs from "./pages/auth/ContactUs";

function App() {
    const [data, setData] = useState({user: null, unread: {total:0}});
    const value = {data, setData};
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
        Api.get('/auth/profile')
            .then((user) => {
                setData({user});
                if(user.role !== 'admin'){
                    alert("Please login with admin user");
                    localStorage.removeItem('token');
                    setData({user: null});
                    window.location.href = '/auth/login';
                }
            })
            .catch(() => {
                localStorage.removeItem('token');
                setData({user: null});
                window.location.href = '/auth/login';
            }).finally(() => {
            setIsReady(true);
        })
    }, [])


    // if (!isReady) {
    //     return <div>Loading...</div>
    // }

    return (
        <div className="App">
            <AppContext.Provider value={value}>
                <BrowserRouter>
                    {/* <Header/> */}
                    <Routes>
                        <Route path={'/'} element={<AuthCheck/>}/>
                        <Route path={'/auth/activate'} element={<ActivateAccount/>}/>
                        <Route path={'/contact'} element={<ContactUs/>}/>
                        <Route path={'/auth/login'} element={<Login/>}/>
                        <Route path={'/auth/forgot'} element={<ForgotPassword/>}/>
                        <Route path={'/auth/reset'} element={<ResetPassword/>}/>
                        <Route path={'/dashboard'} element={<Home/>}/>
                        <Route path={'/:entity'} element={<ListUsers/>}/>
                        <Route path={'/:entity/create'} element={<CreateUser/>}/>
                        <Route path={'/:entity/:id'} element={<ViewUser/>}/>
                        <Route path={'/:entity/:id/edit'} element={<CreateUser/>}/>
                    </Routes>
                </BrowserRouter>
            </AppContext.Provider>
        </div>
    );
}

export default App;
